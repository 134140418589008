// rootReducer.js
import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import enquiriesReducer from './enquiry/reducer';


const rootReducer = combineReducers({
  user: userReducer,
  enquiry: enquiriesReducer,
});

export default rootReducer;

export const GET_ENQUIRIES_LOADING = 'GET_ENQUIRIES_LOADING';
export const GET_ENQUIRIES_SUCCESS = 'GET_ENQUIRIES_SUCCESS';
export const GET_ENQUIRIES_ERROR = 'GET_ENQUIRIES_ERROR';

export const GET_ROOM_OPT_LOADING = 'GET_ROOM_OPT_LOADING';
export const GET_ROOM_OPT_SUCCESS = 'GET_ROOM_OPT_SUCCESS';
export const GET_ROOM_OPT_ERROR = 'GET_ROOM_OPT_ERROR';

export const GET_CHILD_OPT_LOADING = 'GET_CHILD_OPT_LOADING';
export const GET_CHILD_OPT_SUCCESS = 'GET_CHILD_OPT_SUCCESS';
export const GET_CHILD_OPT_ERROR = 'GET_CHILD_OPT_ERROR';

export const GET_ENQ_DETAILS_LOADING = 'GET_ENQ_DETAILS_LOADING';
export const GET_ENQ_DETAILS_SUCCESS = 'GET_ENQ_DETAILS_SUCCESS';
export const GET_ENQ_DETAILS_ERROR = 'GET_ENQ_DETAILS_ERROR';

export const GET_SAVE_ENQ_DETAILS_LOADING = 'GET_SAVE_ENQ_DETAILS_LOADING';
export const GET_SAVE_ENQ_DETAILS_SUCCESS = 'GET_SAVE_ENQ_DETAILS_SUCCESS';
export const GET_SAVE_ENQ_DETAILS_ERROR = 'GET_SAVE_ENQ_DETAILS_ERROR';
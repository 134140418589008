import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { isValidEmail } from "../../helpers";
import Joi from "joi";

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { userLoginAction } from "../../store/user/asyncActions";
import { useSelector } from "react-redux";
import InstallPopup from "../../components/InstallPopup/InstallPopup";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData = localStorage.getItem('user');
  if (userData && userData.trim() !== '') {
    history.push('/');
    return (<></>);
  }

  const loginData = useSelector((state) => state?.user?.login);

  const [loading, setLoading] = useState(false);
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    document.title = 'Login - Neem Holidays';
  }, []);

  useEffect(() => {
    if (!loginData?.loading) {
      if (loginData?.data?.status) {
        localStorage.setItem('user', JSON.stringify(loginData?.data?.data));
        localStorage.setItem('AccessToken', loginData?.data?.data?.auth_token);
        loginData.data = null;
        history.push('/');
      }
      setLoading(false);
    }
  }, [loginData]);

  const loginDataHandleChange = (e) => {
    setLoginFormData({
      ...loginFormData,
      [e.target.name]: e.target.value
    });
  }

  const handleMobileChange = (e) => {
    const val = e.target.value;
    if (val.length <= 10 && !isNaN(val)) {
      loginDataHandleChange(e);
    } else {
      e.preventDefault();
    }
  };

  const [errors, setErrors] = useState({});
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('E-Mail'),
    password: Joi.string()
      .required()
      .label('Password'),
  });

  const do_login = (e) => {
    e.preventDefault();
    const validationResult = schema.validate(loginFormData, { abortEarly: false });

    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      setLoading(true);
      dispatch(userLoginAction(loginFormData));
    }
  }

  return (
    <div className="login_main">
      <form className="w-100" onSubmit={(e) => { do_login(e) }}>
        <div className="logo_div px-2">
          <img
            src={`${process.env.PUBLIC_URL}/static/images/main-logo.png`}
            alt=""
            className="login_logo"
          />
          <Typography variant="h5" className="pt-3" style={{ fontWeight: 'bold' }}>
            Login
          </Typography>
          <Typography variant="subtitle2" className="pb-1 pb-2">
            Please login to continue.
          </Typography>
        </div>
        <div className="px-2">
          <TextField
            className="w-100 mb-1"
            name="email"
            label="E-Mail ID"
            variant="outlined"
            size="small"
            placeholder="Enter your email"
            value={loginFormData?.email}
            error={errors?.email}
            helperText={errors?.email}
            onChange={(e) => loginDataHandleChange(e)}
          />
          <TextField
            className="w-100 mb-1"
            name="password"
            label="Password"
            variant="outlined"
            size="small"
            placeholder="Enter your password"
            type="password"
            value={loginFormData?.password}
            error={errors?.password}
            helperText={errors?.password}
            onChange={(e) => loginDataHandleChange(e)}
          />
          <div className="d_flex justify_content_center">
            <Button
              className="login_form_btn w-100"
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Login
            </Button>
          </div>
        </div>
      </form>
      <InstallPopup />
    </div>
  );
};

export default Login;

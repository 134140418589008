import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import { Button, Card, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Menu, MenuItem, TextField } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getEnquiriesAction } from "../../store/enquiry/asyncActions";

const Enquiries = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = localStorage.getItem('user');

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [printLoading, setPrintLoading] = useState(null);
  const [page, setPage] = useState(1);
  const [enquiries, setEnquiries] = useState([]);
  const [search, setSearch] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);

  const enquiriesData = useSelector((state) => state?.enquiry?.enquiries);

  useEffect(() => {
    document.title = 'Enquiries - Neem Holidays';
  }, []);

  useEffect(() => {
    if (userData && userData.trim() !== '') {
      setUser(JSON.parse(userData));
    }
  }, [userData]);

  const fetchEnquiries = (page = 1) => {
    dispatch(getEnquiriesAction({
      auth_token: user?.auth_token,
      id: user?.id,
      start: page,
      search: search
    }));
  }

  useEffect(() => {
    if (user) {
      setHasMore(true);
      fetchEnquiries(page);
    }
  }, [user, search]);

  useEffect(() => {
    if (enquiriesData?.data?.status) {
      // setEnquiries(enquiriesData?.data?.data);
      if (page === 1) {
        setEnquiries(enquiriesData?.data?.data);
      } else {
        setEnquiries(prevData => [...prevData, ...enquiriesData?.data?.data]);
      }
      if (enquiriesData?.data?.data?.length < 1) {
        setHasMore(false);
      }
      setTimeout(() => {
        enquiriesData.data = null;
      }, 200);
    } else if (enquiriesData?.data?.error) {
      setHasMore(false);
    }
  }, [enquiriesData]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const deleteOrder = (id) => {
    // dispatch(deleteOrderAction({
    //   auth_token: user?.auth_token,
    //   user_type: user?.user_type,
    //   user_id: user?.user_id,
    //   order_id: ID
    // }));
    setDeleteModal(false);
  }

  return (
    <div className="px-1 pt-1">
      <div className="title_With_Back mb-1">
        <Typography variant='h6' color="textSecondary">
          Enquiries List
        </Typography>
        {/* <Button variant="text" color="primary" onClick={() => {
          history.goBack();
        }}>
          <ArrowBackIosIcon />Back
        </Button> */}
      </div>

      <div>
        <TextField
          name="search_qry"
          variant="outlined"
          size="small"
          placeholder="Search by name, lead id, mobile"
          fullWidth
          onChange={(e) => {
            setPage(1);
            setSearch(e?.target?.value);
          }}
        />
      </div>

      <div className="Order_list">

        <InfiniteScroll
          dataLength={enquiries.length || 0}
          next={() => {
            fetchEnquiries(page + 1);
            setPage(prevPage => prevPage + 1);
          }}
          hasMore={hasMore} // Set this to false if there are no more pages to load
          loader={
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress style={{ height: '35px', width: '35px' }} />
            </div>
          }
          scrollThreshold={0.95} // Load more data when the user scrolls to 90% of the page
        >
          {enquiries.map((item, index) => {
            return (
              <Card className="py-1 my-2" key={index}>
                <div className="px-1">
                  <div className="Order_no">
                    <Typography variant='subtitle2' color="textSecondary" style={{ fontSize: '16px' }}>
                      {item?.lead_id}
                    </Typography>
                    <div className="OrderActions">
                      <div className={`Order_status ${item?.status === 'Pending' ? 'pending' : 'approved'}`} onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setOpen(index);
                      }}>
                        {item?.status}
                      </div>
                      {/* <VisibilityIcon color="action" style={{ marginLeft: '10px' }} onClick={() => history.push(`/order-details?id=${item?.id}`)} /> */}
                    </div>
                  </div>
                  <hr className="my-1" />
                  <div>
                    <div className="person_name_div">
                      <Typography variant='subtitle2' color="inherit">
                        {item?.customer_name}
                      </Typography>
                    </div>
                    <div className="person_name_div">
                      <Typography variant='subtitle2' color="textSecondary">
                        Adult :
                      </Typography>
                      <div className="person_count">{item?.adult || 0}</div>
                      <div className="px-1">/</div>
                      <Typography variant='subtitle2' color="textSecondary">
                        Child :
                      </Typography>
                      <div className="person_count">{Number(item?.child) + Number(item?.infants) || 0}</div>
                    </div>
                    <div>
                      <div className="person_name_div">
                        <Typography variant='subtitle2' color="textSecondary">
                          Manaqued By :
                        </Typography>
                        <div className="person_name">{item?.manage_by}</div>
                      </div>
                    </div>
                    <div>
                      <div className="person_name_div">
                        <Typography variant='subtitle2' color="textSecondary">
                          Confirmed Date :
                        </Typography>
                        <div className="person_name">{item?.confirm_date}</div>
                      </div>
                    </div>
                    <div>
                      <div className="person_name_div">
                        <Typography variant='subtitle2' color="textSecondary">
                          Passport Upload Status :
                        </Typography>
                        <div className="person_name">{item?.room_details_status}</div>
                      </div>
                    </div>
                    <div className="d_flex mt-1 order_action_btn">
                      <Button variant="contained" color="primary" fullWidth onClick={() => history.push(`/room-details?id=${item?.booking_id}`)}>
                        Add Room Details
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            )
          })}
        </InfiniteScroll>

      </div>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteOrder()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Enquiries;

import { getChildOtpError, getChildOtpLoading, getChildOtpSuccess, getEnqDetailsError, getEnqDetailsLoading, getEnqDetailsSuccess, getEnquiriesError, getEnquiriesLoading, getEnquiriesSuccess, getRoomOtpError, getRoomOtpLoading, getRoomOtpSuccess, saveEnqDetailsError, saveEnqDetailsLoading, saveEnqDetailsSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getEnquiriesAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getEnquiriesLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/webservices/getEnquiries', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getEnquiriesSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getEnquiriesError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getRoomOptionsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getRoomOtpLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/webservices/FetchRoomOptions', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getRoomOtpSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getRoomOtpError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getChildOptionsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getChildOtpLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/webservices/FetchChildOptions', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getChildOtpSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getChildOtpError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getEnqDetailsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getEnqDetailsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/webservices/FetchEnquiryDetails', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getEnqDetailsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getEnqDetailsError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const saveEnqDetailsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(saveEnqDetailsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/webservices/UpdateEnquiry', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(saveEnqDetailsSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(saveEnqDetailsError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getEnquiriesAction,
  getRoomOptionsAction,
  getChildOptionsAction,
  getEnqDetailsAction,
  saveEnqDetailsAction
};

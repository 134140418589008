import { GET_CHILD_OPT_ERROR, GET_CHILD_OPT_LOADING, GET_CHILD_OPT_SUCCESS, GET_ENQUIRIES_ERROR, GET_ENQUIRIES_LOADING, GET_ENQUIRIES_SUCCESS, GET_ENQ_DETAILS_ERROR, GET_ENQ_DETAILS_LOADING, GET_ENQ_DETAILS_SUCCESS, GET_ROOM_OPT_ERROR, GET_ROOM_OPT_LOADING, GET_ROOM_OPT_SUCCESS, GET_SAVE_ENQ_DETAILS_ERROR, GET_SAVE_ENQ_DETAILS_LOADING, GET_SAVE_ENQ_DETAILS_SUCCESS } from "./actionTypes";

const initialState = {
    enquiries: {
        loading: false,
        data: null,
        error: null
    },
    roomOptions: {
        loading: false,
        data: null,
        error: null
    },
    childOptions: {
        loading: false,
        data: null,
        error: null
    },
    enqDetails: {
        loading: false,
        data: null,
        error: null
    },
    saveEnqDetails: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ENQUIRIES_LOADING:
            return { ...state, enquiries: { ...state.enquiries, loading: true, data: null, error: null } };
        case GET_ENQUIRIES_SUCCESS:
            return { ...state, enquiries: { ...state.enquiries, loading: false, data: action.payload, error: null } };
        case GET_ENQUIRIES_ERROR:
            return { ...state, enquiries: { ...state.enquiries, loading: false, data: null, error: action.payload } };

        case GET_ROOM_OPT_LOADING:
            return { ...state, roomOptions: { ...state.roomOptions, loading: true, data: null, error: null } };
        case GET_ROOM_OPT_SUCCESS:
            return { ...state, roomOptions: { ...state.roomOptions, loading: false, data: action.payload, error: null } };
        case GET_ROOM_OPT_ERROR:
            return { ...state, roomOptions: { ...state.roomOptions, loading: false, data: null, error: action.payload } };

        case GET_CHILD_OPT_LOADING:
            return { ...state, childOptions: { ...state.childOptions, loading: true, data: null, error: null } };
        case GET_CHILD_OPT_SUCCESS:
            return { ...state, childOptions: { ...state.childOptions, loading: false, data: action.payload, error: null } };
        case GET_CHILD_OPT_ERROR:
            return { ...state, childOptions: { ...state.childOptions, loading: false, data: null, error: action.payload } };

        case GET_ENQ_DETAILS_LOADING:
            return { ...state, enqDetails: { ...state.enqDetails, loading: true, data: null, error: null } };
        case GET_ENQ_DETAILS_SUCCESS:
            return { ...state, enqDetails: { ...state.enqDetails, loading: false, data: action.payload, error: null } };
        case GET_ENQ_DETAILS_ERROR:
            return { ...state, enqDetails: { ...state.enqDetails, loading: false, data: null, error: action.payload } };

        case GET_SAVE_ENQ_DETAILS_LOADING:
            return { ...state, saveEnqDetails: { ...state.saveEnqDetails, loading: true, data: null, error: null } };
        case GET_SAVE_ENQ_DETAILS_SUCCESS:
            return { ...state, saveEnqDetails: { ...state.saveEnqDetails, loading: false, data: action.payload, error: null } };
        case GET_SAVE_ENQ_DETAILS_ERROR:
            return { ...state, saveEnqDetails: { ...state.saveEnqDetails, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import { Button, Card, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import Joi, { number } from "joi";
import MRZScanner from "../../components/MRZ/MRZScanner";
import { parse } from 'mrz';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { getChildOptionsAction, getEnqDetailsAction, getRoomOptionsAction, saveEnqDetailsAction } from "../../store/enquiry/asyncActions";
import { toast } from 'react-toastify';
import moment from 'moment';

const RoomDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = localStorage.getItem('user');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [info, setInfo] = useState('');
  const [open, setOpen] = useState(false);

  const [scanning, setScanning] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  const showConfirmationModal = (results) => {
    if (scanning === true) {
      setScanning(false);
      setShowScanner(false);
      const info = parse(MRZString(results));
      // const info = JSON.stringify(parse(MRZString(results)), null, 2);
      // setInfo(JSON.stringify(parse(MRZString(results)), null, 2));
      // setOpen(true);

      updatePersonDetails(updtIndx?.list, updtIndx?.obj, {
        gender: info?.fields?.sex,
        name: `${info?.fields?.firstName} ${info?.fields?.lastName}`,
        passport_no: info?.fields?.documentNumber,
        birthday: decodeDate(info?.fields?.birthDate),
        passport_issued_date: '',
        passport_export_date: decodeDate(info?.fields?.expirationDate),
        passport_issuing_country: info?.fields?.issuingState
      });
    }
  }

  const MRZString = (MRZLineResults) => {
    let str = "";
    for (let index = 0; index < MRZLineResults.length; index++) {
      const lineResult = MRZLineResults[index];
      str = str + lineResult.text;
      if (index != MRZLineResults.length - 1) {
        str = str + "\n";
      }
    }
    return str;
  }

  const correct = () => {
    setShowConfirmation(false);
    setShowScanner(false);
  }

  const rescan = () => {
    setShowConfirmation(false);
    setScanning(true);
  }

  const startScanner = (index, PersonIndex) => {
    setUpdtIndx({ list: index, obj: PersonIndex });
    setShowConfirmation(false);
    setScanning(true);
    setShowScanner(true);
  }

  const stopScanner = () => {
    setScanning(false);
    setShowScanner(false);
  }

  const decodeDate = (dateString) => {
    // const yearYY = dateString.slice(0, 2);
    // const month = dateString.slice(2, 4);
    // const day = dateString.slice(4, 6);
    // const currentYear = new Date().getFullYear();
    // const currentCentury = Math.floor(currentYear / 100) * 100;
    // const fullYear = parseInt(yearYY, 10);
    // const fourDigitYear = fullYear > (currentYear % 100) ? currentCentury - 100 + fullYear : currentCentury + fullYear;
    // const formattedDate = `${fourDigitYear}-${month}-${day}`;
    // return formattedDate;
    const yearYY = dateString.slice(0, 2);
    const month = dateString.slice(2, 4);
    const day = dateString.slice(4, 6);
    const currentYear = new Date().getFullYear();
    const currentCentury = Math.floor(currentYear / 100) * 100;
    const fullYear = parseInt(yearYY, 10);
    let fourDigitYear;

    if (fullYear >= 50 && fullYear <= 99) {
      fourDigitYear = currentCentury - 100 + fullYear;
    } else {
      fourDigitYear = currentCentury + fullYear;
    }

    const formattedDate = `${fourDigitYear}-${month}-${day}`;
    return formattedDate;
  };

  function checkExpiration(dateString) {
    if (!dateString) {
      return false;
    }
    const expirationDate = moment(dateString, 'YYYY-MM-DD');
    const today = moment();
    const diffDays = expirationDate.diff(today, 'days');
    if (diffDays < 0) {
      return "This date has already expired.";
    }
    const remainingMonths = expirationDate.diff(today, 'months');
    const remainingDays = expirationDate.diff(today.add(remainingMonths, 'months'), 'days');
    if (remainingMonths > 6 || (remainingMonths === 6 && remainingDays > 0)) {
      return false;
    }
    return `Will expire in ${remainingMonths} month(s) and ${remainingDays} day(s).`;
  }

  useEffect(() => {
    document.title = 'Add Room Details - Neem Holidays';
  }, []);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redir, setRedir] = useState(null);

  const roomOptData = useSelector((state) => state?.enquiry?.roomOptions);
  const childOptData = useSelector((state) => state?.enquiry?.childOptions);
  const detailsData = useSelector((state) => state?.enquiry?.enqDetails);
  const saveData = useSelector((state) => state?.enquiry?.saveEnqDetails);

  const [rooms, setRooms] = useState([]);
  const [roomOptList, setRoomOptList] = useState([]);
  const [childOptList, setChildOptList] = useState([]);
  const [roomOpt, setRoomOpt] = useState('');
  const [updtIndx, setUpdtIndx] = useState({ list: undefined, obj: undefined });
  const [roomDetails, setRoomDetails] = useState(null);

  useEffect(() => {
    if (userData && userData.trim() !== '') {
      setUser(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (user && id && id.trim() !== '') {
      dispatch(getRoomOptionsAction({
        auth_token: user?.auth_token,
        id: user?.id
      }));
      dispatch(getChildOptionsAction({
        auth_token: user?.auth_token,
        id: user?.id
      }));
      dispatch(getEnqDetailsAction({
        auth_token: user?.auth_token,
        id: user?.id,
        lead_id: id
      }));
    }
  }, [user, id]);

  useEffect(() => {
    if (roomOptData?.data?.status) {
      setRoomOptList(roomOptData?.data?.data);
      roomOptData.data = null;
    }
  }, [roomOptData]);

  useEffect(() => {
    if (childOptData?.data?.status) {
      setChildOptList(childOptData?.data?.data);
      childOptData.data = null;
    }
  }, [childOptData]);

  useEffect(() => {
    if (detailsData?.data?.status) {
      const data = detailsData?.data?.data;
      setRooms(data?.rooms);
      setRoomDetails(data);
      detailsData.data = null;
    }
  }, [detailsData]);

  useEffect(() => {
    if (saveData?.loading === false) {
      setLoading(false);
      if (saveData?.data?.status) {
        history.push('/');
        saveData.data = null;
      }
    }
  }, [saveData]);

  // const addRoom = () => {
  //   const [members, roomId] = roomOpt.split("|");
  //   const [adultCount, childCount] = members.split("-").map(Number);
  //   const persons = [];
  //   const personTemplate = {
  //     gender: '',
  //     name: '',
  //     passport_no: '',
  //     birthday: '',
  //     passport_issued_date: '',
  //     passport_export_date: '',
  //     passport_issued_place: ''
  //   };
  //   for (let i = 0; i < adultCount; i++) {
  //     persons.push({ ...personTemplate, memname: '0' });
  //   }
  //   for (let i = 0; i < childCount; i++) {
  //     persons.push({ ...personTemplate, memname: '' });
  //   }
  //   setRooms(prevRooms => [...prevRooms, persons]);
  // };

  const addRoom = () => {
    if (roomOpt.trim() === '') {
      toast.error('Please select room options');
      return;
    }
    const [members, roomId] = roomOpt.split("|");
    const [adultCount, childCount] = members.split("-").map(Number);
    const personTemplate = {
      gender: '',
      name: '',
      passport_no: '',
      birthday: '',
      passport_issued_date: '',
      passport_export_date: '',
      passport_issued_place: '',
      passport_issuing_country: '',
      pan_no: '',
      aadhar_card: '',
      voter_card: '',
      memname: ''
    };
    const newRoom = {
      room_option: roomId, // Assign room option (ID)
      data: [] // Initialize data array for members
    };
    for (let i = 0; i < adultCount; i++) {
      newRoom.data.push({ ...personTemplate, memname: '0' }); // Add adult members
    }
    for (let i = 0; i < childCount; i++) {
      newRoom.data.push({ ...personTemplate, memname: '' }); // Add child members
    }
    setRooms(prevRooms => [...prevRooms, newRoom]);
  };


  // const countPerson = (room) => {
  //   const adult = room.filter(x => x?.role === 'Adult').length;
  //   const child = room.filter(x => x?.role === 'Child').length;
  //   let result = [];
  //   if (adult > 0) {
  //     result.push(`${adult} Adult`);
  //   }
  //   if (child > 0) {
  //     result.push(`${child} Child`);
  //   }
  //   return result.join(' & ') || 'No people in the room';
  // }

  const countPerson = (room) => {
    const adult = room.filter(x => x?.memname === '0').length;
    const child = room.filter(x => x?.memname !== '0').length;
    let result = [];
    if (adult > 0) {
      result.push(`${adult} Adult`);
    }
    if (child > 0) {
      result.push(`${child} Child`);
    }
    return result.join(' & ') || 'No people in the room';
  }

  // const updatePersonDetails = (listIndex, objectIndex, updatedObject) => {
  //   const newData = rooms.map((list, idx) => {
  //     if (idx === listIndex) {
  //       return list.map((obj, objIdx) => {
  //         if (objIdx === objectIndex) {
  //           return { ...obj, ...updatedObject };
  //         }
  //         return obj;
  //       });
  //     }
  //     return list;
  //   });
  //   setRooms(newData);
  // };

  const updatePersonDetails = (roomIndex, dataIndex, updatedData) => {
    setRooms(prevState => {
      const newState = [...prevState];
      newState[roomIndex].data = [...newState[roomIndex].data];
      newState[roomIndex].data[dataIndex] = {
        ...newState[roomIndex].data[dataIndex],
        ...updatedData
      };
      return newState;
    });
  };

  // const updatePersonFieldDetails = (listIndex, objectIndex, key, value) => {
  //   const newData = rooms.map((list, idx) => {
  //     if (idx === listIndex) {
  //       return list.map((obj, objIdx) => {
  //         if (objIdx === objectIndex) {
  //           return {
  //             ...obj,
  //             [key]: value,
  //           };
  //         }
  //         return obj;
  //       });
  //     }
  //     return list;
  //   });
  //   setRooms(newData);
  // };

  const updatePersonFieldDetails = (roomIndex, dataIndex, field, newValue) => {
    setRooms(prevState => {
      const newState = [...prevState];
      newState[roomIndex].data = [...newState[roomIndex].data];
      newState[roomIndex].data[dataIndex][field] = newValue;
      return newState;
    });
  };

  const removeRoom = (index) => {
    const newItems = rooms.filter((item, i) => i !== index);
    setRooms(newItems);
  }

  const clearDetails = (index, PersonIndex) => {
    updatePersonDetails(index, PersonIndex, {
      gender: '',
      name: '',
      passport_no: '',
      birthday: '',
      passport_issued_date: '',
      passport_export_date: '',
      passport_issued_place: '',
      passport_issuing_country: '',
      pan_no: '',
      aadhar_card: '',
      voter_card: '',
    });
  }

  const [errors, setErrors] = useState({});
  const schema = Joi.array().items(
    Joi.object({
      room_option: Joi.string().required(),
      data: Joi.array().items(
        Joi.object({
          memname: Joi.string().required().label('Child Option')
        }).unknown(true)
      )
    })
  );

  const save = () => {
    const { error } = schema.validate(rooms, { abortEarly: false });
    if (error) {
      const errorDetails = {};
      error.details.forEach((err) => {
        const path = err.path.join('.');
        errorDetails[path] = err.message;
      });
      setErrors(errorDetails);
      toast.error('Please fill required fields');
    } else {
      setErrors({});
      setLoading(true);
      dispatch(saveEnqDetailsAction({
        auth_token: user?.auth_token,
        id: user?.id,
        lead_id: roomDetails?.booking_id,
        customer_id: roomDetails?.customer_id,
        rooms: rooms
      }));
    }
  }

  return (
    <>
      <div className="px-1 pt-1 pb-3">
        <div className="title_With_Back mb-1">
          <Typography variant='h6' color="textSecondary">
            Add Room Details
          </Typography>
          <Button variant="text" color="primary" onClick={() => {
            history.goBack();
          }}>
            <ArrowBackIosIcon />Back
          </Button>
        </div>
        <div>
          <div className="person_name_details_title">
            {roomDetails?.customer_name} <span>({roomDetails?.lead_id})</span>
          </div>
          <div className="person_name_div">
            <Typography variant='subtitle2' color="textSecondary">
              Adult :
            </Typography>
            <div className="person_count">{roomDetails?.adult || 0}</div>
            <div className="px-1">/</div>
            <Typography variant='subtitle2' color="textSecondary">
              Child :
            </Typography>
            <div className="person_count">{Number(roomDetails?.child) + Number(roomDetails?.infants) || 0}</div>
          </div>
        </div>

        <hr />

        <div>
          {rooms && rooms.map((room, index) => {
            return (
              <div className="w-100" key={index}>
                <div className="mt-2 d_flex align_items_center w-100">
                  <Typography variant='h6' color="textSecondary" className="oreder_summary_title">
                    Room #{index + 1} ({countPerson(room?.data)})
                  </Typography>
                  <CloseIcon className="item_delete_btn" onClick={() => removeRoom(index)} />
                </div>
                {room?.data && room?.data.map((person, PersonIndex) => {
                  return (
                    <Card className="py-1 px-1 my-1" key={PersonIndex}>
                      <div className="order_item_title">
                        <Typography variant='subtitle2' color="inherit">
                          #{PersonIndex + 1} ({person?.memname === '0' ? 'Adult' : 'Child'})
                        </Typography>
                        <div className="item_scan_btn">
                          <Button variant="outlined" color="primary" style={{ marginRight: '10px' }} onClick={() => startScanner(index, PersonIndex)}>
                            Scan Passport
                          </Button>
                          <Button variant="outlined" color="default" onClick={() => clearDetails(index, PersonIndex)}>
                            Clear
                          </Button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <TextField
                          label="Gender"
                          className="login_form_field mb-1"
                          name="gender"
                          variant="outlined"
                          size="small"
                          fullWidth
                          // error={errors?.items?.[index]?.qty}
                          // helperText={errors?.items?.[index]?.qty}
                          value={person?.gender}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'gender', e.target.value)}
                        />
                        <TextField
                          label="Name"
                          className="login_form_field mb-1"
                          name="name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={person?.name}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'name', e.target.value)}
                        />
                        <TextField
                          label="Passport No."
                          className="login_form_field mb-1"
                          name="passport_no"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={person?.passport_no}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'passport_no', e.target.value)}
                        />
                        <TextField
                          label="Birth Date"
                          className="login_form_field mb-1"
                          name="birthday"
                          variant="outlined"
                          size="small"
                          type="date"
                          fullWidth
                          value={person?.birthday}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'birthday', e.target.value)}
                        />
                        <TextField
                          label="Issue Date"
                          className="login_form_field mb-1"
                          name="passport_issued_date"
                          variant="outlined"
                          size="small"
                          type="date"
                          fullWidth
                          value={person?.passport_issued_date}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'passport_issued_date', e.target.value)}
                        />
                        <TextField
                          label="Passport Expiry Date"
                          className="login_form_field mb-1"
                          name="passport_export_date"
                          variant="outlined"
                          size="small"
                          type="date"
                          fullWidth
                          value={person?.passport_export_date}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'passport_export_date', e.target.value)}
                          error={checkExpiration(person?.passport_export_date)}
                          helperText={checkExpiration(person?.passport_export_date)}
                        />
                        <TextField
                          label="Issued Place"
                          className="login_form_field date mb-1"
                          name="passport_issued_place"
                          variant="outlined"
                          size="small"
                          placeholder="Issued Place"
                          fullWidth
                          value={person?.passport_issued_place}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'passport_issued_place', e.target.value)}
                        />
                        <TextField
                          label="Issued Country"
                          className="login_form_field date mb-1"
                          name="passport_issuing_country"
                          variant="outlined"
                          size="small"
                          placeholder="Issued Country"
                          fullWidth
                          value={person?.passport_issuing_country}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'passport_issuing_country', e.target.value)}
                        />
                        <TextField
                          label="PAN Card"
                          className="login_form_field date mb-1"
                          name="pan_no"
                          variant="outlined"
                          size="small"
                          placeholder="PAN Card"
                          fullWidth
                          value={person?.pan_no}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'pan_no', e.target.value)}
                        />
                        <TextField
                          label="Aadhar Card"
                          className="login_form_field date mb-1"
                          name="aadhar_card"
                          variant="outlined"
                          size="small"
                          placeholder="Aadhar Card"
                          fullWidth
                          value={person?.aadhar_card}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'aadhar_card', e.target.value)}
                        />
                        <TextField
                          label="Voter Card"
                          className="login_form_field date mb-1"
                          name="voter_card"
                          variant="outlined"
                          size="small"
                          placeholder="Voter Card"
                          fullWidth
                          value={person?.voter_card}
                          onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'voter_card', e.target.value)}
                        />
                        {person?.memname !== '0' &&
                          <FormControl error={errors?.[`${index}.data.${PersonIndex}.memname`]} fullWidth variant="outlined" size="small" className="mb-1">
                            <InputLabel>Choose Child Option</InputLabel>
                            <Select
                              label="Choose Child Option"
                              value={person?.memname}
                              onChange={(e) => updatePersonFieldDetails(index, PersonIndex, 'memname', e.target.value)}
                            >
                              {childOptList.map((opt, index) => {
                                return (
                                  <MenuItem value={opt?.id} key={index}>{opt?.option}</MenuItem>
                                )
                              })}
                            </Select>
                            {errors?.[`${index}.data.${PersonIndex}.memname`] && (
                              <FormHelperText>{errors?.[`${index}.data.${PersonIndex}.memname`]}</FormHelperText>
                            )}
                          </FormControl>
                        }
                      </div>
                    </Card>
                  )
                })}
              </div>
            )
          })}

          <div className="mb-2 mt-2">
            <FormControl fullWidth variant="outlined" size="small" className="mb-1">
              <InputLabel>Choose Accomodation</InputLabel>
              <Select
                value={roomOpt}
                label="Choose Accomodation"
                onChange={(e) => setRoomOpt(e.target.value)}
              >
                {roomOptList.map((opt, index) => {
                  return (
                    <MenuItem value={`${opt?.members}|${opt?.id}`} key={index}>{opt?.option}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <Button variant="outlined" color="primary" fullWidth onClick={() => addRoom()}>
              + ADD ROOM
            </Button>
          </div>
        </div>
      </div>

      <div className="new_order_footer">
        <Button variant="contained" color="primary" fullWidth disabled={loading} onClick={() => save()}>
          Submit
        </Button>
      </div>

      {showScanner &&
        <MRZScanner
          // license="DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyNzExMzcxLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAyNzExMzcxIiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjoxMzA1NDI2OTg4fQ=="
          license="DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyODMxMTg2LVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMjgzMTE4NiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi0xMjQyNDg1MzQ2fQ=="
          scanning={scanning}
          hideSelect={true}
          onResourcesLoadStarted={() => setShowProgress(true)}
          onResourcesLoadProgress={(_resourcesPath, _progress) => {
            const percent = (_progress.loaded / _progress.total * 100).toFixed(2);
            setProgress(parseInt(percent));
          }}
          onResourcesLoaded={() => {
            setShowProgress(false);
            setProgress(0);
          }}
          onScanned={(results) => (showConfirmationModal(results))}
        >
          {showConfirmation &&
            <div className="confirmation modal">
              <pre>
                {MRZString()}
              </pre>
              <button onClick={() => correct()}>Correct</button>
              <button onClick={() => rescan()} >Rescan</button>
            </div>
          }
          {showProgress &&
            <div className="progress modal">
              <div>Loading the model...</div>
              <div>
                Progress: <span>{progress}</span>%
              </div>
            </div>
          }
          <Button variant="contained" color="default" className="close-scanner-button" onClick={() => stopScanner()}>
            Stop Scanner
          </Button>
        </MRZScanner>
      }

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          {info}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RoomDetails;

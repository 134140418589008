import { GET_CHILD_OPT_ERROR, GET_CHILD_OPT_LOADING, GET_CHILD_OPT_SUCCESS, GET_ENQUIRIES_ERROR, GET_ENQUIRIES_LOADING, GET_ENQUIRIES_SUCCESS, GET_ENQ_DETAILS_ERROR, GET_ENQ_DETAILS_LOADING, GET_ENQ_DETAILS_SUCCESS, GET_ROOM_OPT_ERROR, GET_ROOM_OPT_LOADING, GET_ROOM_OPT_SUCCESS, GET_SAVE_ENQ_DETAILS_ERROR, GET_SAVE_ENQ_DETAILS_LOADING, GET_SAVE_ENQ_DETAILS_SUCCESS } from './actionTypes';

// get Enquiries
const getEnquiriesLoading = () => {
  return {
    type: GET_ENQUIRIES_LOADING,
  };
};

const getEnquiriesSuccess = (data) => {
  return {
    type: GET_ENQUIRIES_SUCCESS,
    payload: data,
  };
};

const getEnquiriesError = (error) => {
  return {
    type: GET_ENQUIRIES_ERROR,
    payload: error,
  };
};

// get Room Options
const getRoomOtpLoading = () => {
  return {
    type: GET_ROOM_OPT_LOADING,
  };
};

const getRoomOtpSuccess = (data) => {
  return {
    type: GET_ROOM_OPT_SUCCESS,
    payload: data,
  };
};

const getRoomOtpError = (error) => {
  return {
    type: GET_ROOM_OPT_ERROR,
    payload: error,
  };
};

// get Child Options
const getChildOtpLoading = () => {
  return {
    type: GET_CHILD_OPT_LOADING,
  };
};

const getChildOtpSuccess = (data) => {
  return {
    type: GET_CHILD_OPT_SUCCESS,
    payload: data,
  };
};

const getChildOtpError = (error) => {
  return {
    type: GET_CHILD_OPT_ERROR,
    payload: error,
  };
};

// get Enquiry Details
const getEnqDetailsLoading = () => {
  return {
    type: GET_ENQ_DETAILS_LOADING,
  };
};

const getEnqDetailsSuccess = (data) => {
  return {
    type: GET_ENQ_DETAILS_SUCCESS,
    payload: data,
  };
};

const getEnqDetailsError = (error) => {
  return {
    type: GET_ENQ_DETAILS_ERROR,
    payload: error,
  };
};

// Save Enquiry Details
const saveEnqDetailsLoading = () => {
  return {
    type: GET_SAVE_ENQ_DETAILS_LOADING,
  };
};

const saveEnqDetailsSuccess = (data) => {
  return {
    type: GET_SAVE_ENQ_DETAILS_SUCCESS,
    payload: data,
  };
};

const saveEnqDetailsError = (error) => {
  return {
    type: GET_SAVE_ENQ_DETAILS_ERROR,
    payload: error,
  };
};

export { 
    getEnquiriesLoading, 
    getEnquiriesSuccess, 
    getEnquiriesError,

    getRoomOtpLoading, 
    getRoomOtpSuccess, 
    getRoomOtpError,

    getChildOtpLoading, 
    getChildOtpSuccess, 
    getChildOtpError,

    getEnqDetailsLoading, 
    getEnqDetailsSuccess, 
    getEnqDetailsError,

    saveEnqDetailsLoading, 
    saveEnqDetailsSuccess, 
    saveEnqDetailsError,
};

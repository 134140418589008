import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { isValidEmail } from "../../helpers";
import Joi from "joi";

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useSelector } from "react-redux";
import { resendOtpAction, verifyOtpAction } from "../../store/user/asyncActions";

const Verification = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData = localStorage.getItem('user');
  if (userData && userData.trim() !== '') {
    history.push('/');
    return (<></>);
  }

  const loginData = localStorage.getItem('login_message');
  const mobile = localStorage.getItem('mobile');

  const verificationData = useSelector((state) => state?.user?.verifyOtp);
  const resendOtpData = useSelector((state) => state?.user?.resendOtp);

  const [loading, setLoading] = useState(false);
  const [loginMsg, setLoginMsg] = useState('');
  const [verificationForm, setVerificationForm] = useState({
    mobile: '',
    otp: ''
  });

  useEffect(() => {
    document.title = 'Verifiation - Neem Holidays';
  }, []);

  useEffect(() => {
    if (loginData && loginData.trim() !== '') {
      setLoginMsg(loginData);
    }
  }, [loginData]);

  useEffect(() => {
    if (mobile && mobile.trim() !== '') {
      setVerificationForm({
        ...verificationForm,
        mobile: mobile
      });
    }
  }, [mobile]);

  useEffect(() => {
    if (verificationData?.data?.success) {
      localStorage.setItem('user', JSON.stringify(verificationData?.data?.data));

      verificationData.data = null;
      localStorage.removeItem('login_message');
      localStorage.removeItem('mobile');
      history.push('/');
    } else if (verificationData?.data?.error) {
      setLoading(false);
    }
  }, [verificationData]);

  useEffect(() => {
    if (resendOtpData?.data?.success) {
      setLoading(false);
    } else if (resendOtpData?.data?.error) {
      setLoading(false);
    }
  }, [resendOtpData]);

  const formDataHandleChange = (e) => {
    setVerificationForm({
      ...verificationForm,
      [e.target.name]: e.target.value
    });
  }

  const handleOtpChange = (e) => {
    const val = e.target.value;
    if (val.length <= 6 && !isNaN(val)) {
      formDataHandleChange(e);
    } else {
      e.preventDefault();
    }
  };

  const [errors, setErrors] = useState({});
  const schema = Joi.object({
    mobile: Joi.string()
      .regex(/^[0-9]{10}$/) // Assuming a 10-digit mobile number
      .required()
      .label('Mobile Number')
      .messages({
        'string.pattern.base': 'Mobile number must be 10 digits',
        'any.required': 'Mobile number is required',
      }),
    otp: Joi.string().min(6).required().label('OTP'),
  });

  const do_verification = (e) => {
    e.preventDefault();

    const validationResult = schema.validate(verificationForm, { abortEarly: false });

    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      setLoading(true);
      dispatch(verifyOtpAction(verificationForm));
    }
  }

  const resend_otp = () => {
    if (verificationForm?.mobile && verificationForm?.mobile.trim() !== '') {
      setLoading(true);
      dispatch(resendOtpAction({
        mobile: verificationForm?.mobile
      }));
    }
  }

  return (
    <div className="login_main">
      <form onSubmit={(e) => { do_verification(e) }}>
        <div className="logo_div px-2">
          <img
            src={`${process.env.PUBLIC_URL}/static/images/main-logo.png`}
            alt=""
            className="login_logo"
          />
          <Typography variant="h5" className="pt-3">
            Verification
          </Typography>

          <Typography variant="body1" color="primary" align="center" className="pb-2">
            {loginMsg}
          </Typography>
        </div>
        <div className="px-2 mb-2">
          <TextField
            className="login_form_field"
            name="otp"
            label="OTP"
            variant="outlined"
            size="small"
            placeholder="Enter 6 digit otp"
            value={verificationForm?.otp}
            error={errors?.otp}
            helperText={errors?.otp}
            onChange={(e) => handleOtpChange(e)}
          />
          <Button
            className="login_form_btn"
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            <ArrowForwardIosIcon />
          </Button>
        </div>
        <div className="px-2">
          <Button
            variant="text"
            color="primary"
            type="button"
            fullWidth
            disabled={loading}
            onClick={() => resend_otp()}
          >
            Resend OTP
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Verification;
